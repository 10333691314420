<script>
import api from "@/api";
import Loader from "vue-element-loading";
export default {
  name: "AdminList",
  components: {
    Loader,
  },
  data() {
    return {
      loading: true,
      managers: [],
      targetManager: null,
      newHiddenView: null,
      hiddenViews: [
        { name: "", value: null },
        { name: "Space >> Bookings", value: "space.bookings" },
        {
          name: "Space >> Recurring Bookings",
          value: "space.recurring_bookings",
        },
        { name: "Space >> Settings", value: "space.settings" },
        { name: "Space >> Equipment List", value: "space.equipment_list" },
        { name: "Billing", value: "billing" },
        { name: "Billing >> Paid", value: "billing.paid" },
        { name: "Billing >> Unpaid", value: "billing.unpaid" },
        { name: "Payment Policies", value: "payment_policies" },
        { name: "Clients", value: "clients" },
      ],
    };
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
  },
  methods: {
    removeManager(manager) {
      this.loading = true;
      const data = {
        slug: this.slug,
        manager: manager.id,
      };
      return api.Util.axios.post("/managers/remove_manager/", data).then(() => {
        return this.getUsers();
      });
    },
    removeHiddenView(manager, hiddenView) {
      const hiddenViews = this.extractHiddenViews(manager.hidden_views);
      let spliceIndex = -1;
      for (const index in hiddenViews) {
        const item = hiddenViews[index];
        if (item == hiddenView) {
          spliceIndex = index;
          break;
        }
      }
      if (spliceIndex === -1) {
        return;
      }
      hiddenViews.splice(spliceIndex, 1);
      this.loading = true;
      return this.saveHiddenView(manager, hiddenViews);
    },
    convertHiddenView(value) {
      const hiddenView = this.hiddenViews.find((item) => item.value === value);
      if (hiddenView) {
        return hiddenView;
      }
      return {};
    },
    getUsers() {
      return api.Util.axios
        .get(`/managers/list_managers/?slug=${this.slug}`)
        .then((response) => {
          this.managers = response.data;
          this.loading = false;
        });
    },
    setHiddenView(manager) {
      this.targetManager = manager;
    },
    extractHiddenViews(str) {
      if (str === null) {
        return [];
      }
      // check item truthiness
      return str.split(",").filter((item) => item);
    },
    addHiddenView() {
      if (!this.newHiddenView) {
        return;
      }
      const hiddenViews = this.extractHiddenViews(
        this.targetManager.hidden_views
      );
      if (hiddenViews.includes(this.newHiddenView)) {
        return;
      }
      this.loading = true;
      hiddenViews.push(this.newHiddenView);
      return this.saveHiddenView(this.targetManager, hiddenViews);
    },
    saveHiddenView(targetManager, hiddenViews) {
      const data = {
        manager: targetManager.id,
        hidden_views: hiddenViews.join(","),
        slug: this.slug,
      };
      return api.Util.axios
        .post(`/managers/add_hidden_views/`, data)
        .then(() => {
          this.getUsers();
        });
    },
  },
  async created() {
    await this.getUsers();
    this.loading = false;
  },
};
</script>

<template>
  <div class="px-1">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 mt-2 py-4" id="inner">
        <loader :active="loading" />
        <div class="row justify-content-center mb-2">
          <div class="col text-center">
            <h3>Users</h3>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Hidden Views</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="managers.length === 0">
                <td colspan="20">
                  <div class="text-center">No Users Found.</div>
                </td>
              </tr>
              <tr v-for="manager in managers" :key="manager.id">
                <td>{{ manager.user.name }}</td>
                <td>{{ manager.user.email }}</td>
                <td>
                  <div class="mb-2">
                    <div
                      class="hiddenView"
                      v-if="
                        extractHiddenViews(manager.hidden_views).length === 0
                      "
                    >
                      No Hidden Views Yet
                    </div>
                    <div
                      class="hiddenView"
                      :key="hiddenView"
                      v-for="hiddenView in extractHiddenViews(
                        manager.hidden_views
                      )"
                    >
                      {{ convertHiddenView(hiddenView).name }}
                      <a
                        href="#"
                        @click.prevent="removeHiddenView(manager, hiddenView)"
                        >(Remove)</a
                      >
                    </div>
                  </div>

                  <div class="row">
                    <div class="col" v-if="targetManager == manager">
                      <select
                        class="form-control"
                        @change="addHiddenView"
                        v-model="newHiddenView"
                      >
                        <template v-for="hiddenView in hiddenViews">
                          <option
                            :key="hiddenView.name"
                            :value="hiddenView.value"
                          >
                            {{ hiddenView.name }}
                          </option>
                        </template>
                      </select>
                    </div>
                    <div class="col" v-else>
                      <button
                        class="btn btn-primary small"
                        @click.prevent="setHiddenView(manager)"
                      >
                        Add HiddenView
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <button
                      class="btn btn-danger small"
                      @click.prevent="removeManager(manager)"
                    >
                      <i class="fas fa-trash"></i> Remove As Manager
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#inner {
  background: white;
  border: 1px solid #ddd;
  border-radius: 3px;
}
.btn.small {
  font-size: 12px;
}
</style>
