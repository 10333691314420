var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-1"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-10 mt-2 py-4",attrs:{"id":"inner"}},[_c('loader',{attrs:{"active":_vm.loading}}),_vm._m(0),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped table-bordered"},[_vm._m(1),_c('tbody',[(_vm.managers.length === 0)?_c('tr',[_vm._m(2)]):_vm._e(),_vm._l((_vm.managers),function(manager){return _c('tr',{key:manager.id},[_c('td',[_vm._v(_vm._s(manager.user.name))]),_c('td',[_vm._v(_vm._s(manager.user.email))]),_c('td',[_c('div',{staticClass:"mb-2"},[(
                      _vm.extractHiddenViews(manager.hidden_views).length === 0
                    )?_c('div',{staticClass:"hiddenView"},[_vm._v(" No Hidden Views Yet ")]):_vm._e(),_vm._l((_vm.extractHiddenViews(
                      manager.hidden_views
                    )),function(hiddenView){return _c('div',{key:hiddenView,staticClass:"hiddenView"},[_vm._v(" "+_vm._s(_vm.convertHiddenView(hiddenView).name)+" "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.removeHiddenView(manager, hiddenView)}}},[_vm._v("(Remove)")])])})],2),_c('div',{staticClass:"row"},[(_vm.targetManager == manager)?_c('div',{staticClass:"col"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.newHiddenView),expression:"newHiddenView"}],staticClass:"form-control",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.newHiddenView=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.addHiddenView]}},[_vm._l((_vm.hiddenViews),function(hiddenView){return [_c('option',{key:hiddenView.name,domProps:{"value":hiddenView.value}},[_vm._v(" "+_vm._s(hiddenView.name)+" ")])]})],2)]):_c('div',{staticClass:"col"},[_c('button',{staticClass:"btn btn-primary small",on:{"click":function($event){$event.preventDefault();return _vm.setHiddenView(manager)}}},[_vm._v(" Add HiddenView ")])])])]),_c('td',[_c('div',[_c('button',{staticClass:"btn btn-danger small",on:{"click":function($event){$event.preventDefault();return _vm.removeManager(manager)}}},[_c('i',{staticClass:"fas fa-trash"}),_vm._v(" Remove As Manager ")])])])])})],2)])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center mb-2"},[_c('div',{staticClass:"col text-center"},[_c('h3',[_vm._v("Users")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Email")]),_c('th',[_vm._v("Hidden Views")]),_c('th',[_vm._v("Actions")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"20"}},[_c('div',{staticClass:"text-center"},[_vm._v("No Users Found.")])])
}]

export { render, staticRenderFns }