<script>
export default {
  name: "AdminIndex",
  computed: {
    isAdmin() {
      return this.$store.getters["Manager/isAdmin"](this.slug);
    },
    slug() {
      return this.$route.params.slug;
    },
  },
  mounted() {
    if (this.$route.name === "Admin") {
      this.$router.push({ name: "AdminList" });
    }
  },
  created() {
    if (!this.isAdmin) {
      this.$router.back();
    }
  },
};
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <hr class="mt-2 d-sm-none" />
      <div class="col-12" id="nav">
        <div>
          <router-link :to="{ name: 'AdminAdd' }">Add User</router-link>
        </div>
        <div>
          <router-link :to="{ name: 'AdminList' }">List Users</router-link>
        </div>
      </div>
      <div class="col">
        <router-view />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#nav {
  display: flex;
  flex-direction: row;
  & > div {
    display: flex;
    margin: 0px 7px;
    width: auto;
    a {
      padding: 6px 10px;
      background: #b4bec3;
      border-radius: 3px;
      text-decoration: none;
      color: inherit;
      &.router-link-exact-active {
        background: rgb(75, 90, 100);
        color: white;
      }
    }
  }
}
</style>
