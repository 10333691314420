<script>
import api from "@/api";
import Loader from "vue-element-loading";
export default {
  name: "AdminAdd",
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
      email: null,
      performedSearch: false,
      foundEmail: null,
    };
  },
  watch: {
    email(newVal) {
      if (!newVal) {
        this.performedSearch = false;
      }
    },
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
  },
  methods: {
    addFoundEmail() {
      this.loading = true;
      const data = {
        email: this.foundEmail,
        slug: this.slug,
      };
      return api.Util.axios
        .post("/managers/add_space_manager/", data)
        .then((response) => {
          alert(response.data.message);
          this.email = this.foundEmail = null;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
          alert("Error performing action.");
        });
    },
    async search() {
      this.loading = true;
      let response = null;
      try {
        response = await api.Util.axios.get(
          `/managers/search_users/?email=${this.email}`
        );
      } catch (err) {
        console.error(err);
        this.loading = false;
        return;
      }
      this.loading = false;
      this.performedSearch = true;
      if (response.data.email) {
        this.foundEmail = response.data.email;
        return;
      }
      this.foundEmail = null;
    },
  },
};
</script>

<template>
  <div class="px-1">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 mt-2 py-4" id="inner">
        <Loader :active="loading" />
        <div class="row justify-content-center mb-2">
          <div class="col text-center">
            <h5>Add User</h5>
          </div>
        </div>

        <form class="row" @submit.prevent="search">
          <div class="col">
            <input
              class="form-control"
              type="email"
              v-model="email"
              pacelholder="Enter Email"
            />
          </div>
          <div class="col">
            <button class="btn btn-primary" @click.prevent="search">
              Search
            </button>
          </div>
        </form>

        <div class="row justify-content-center mt-2" v-if="performedSearch">
          <div class="col text-center mt-2">
            <button
              v-if="foundEmail"
              class="btn btn-primary"
              @click="addFoundEmail"
            >
              Add User {{ foundEmail }} as Manager of this space?
            </button>
            <span v-else>No User Found.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#inner {
  background: white;
  border: 1px solid #ddd;
  border-radius: 3px;
}
</style>
